<template>
  <div class="input-otp relative mx-auto">
    <label>
      <input
        :value="modelValue"
        ref="input"
        autofocus
        type="text"
        mode="numeric"
        min="0"
        max="999999"
        class="hide-input absolute top-0 left-0 w-full focus:outline-none bg-transparent colors.transparent"
        @keyup="handleChange"
        inputmode="numeric"
      />
    </label>
    <div class="list-otp flex align-middle justify-between mx-auto">
      <div
        class="single-otp border-b-2 border-app"
        v-for="(item, i) in data"
        :key="i"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['changeData'],
  name: 'BaseInputOTP',
  props: {
    modelValue: {
      type: String,
    },
    data: {
      type: Array,
    },
    separator: {
      type: String,
    },
    focus: {
      type: Boolean,
    },
    inputClasses: {
      type: String,
    },
    shouldAutoFocus: {
      type: Boolean,
    },
    inputType: {
      type: String,
      default() {
        return 'tel';
      },
    },
    isLastChild: {
      type: Boolean,
    },
    lengthOTP: {
      type: Number,
      default: 4,
    },
  },
  methods: {
    handleChange(e) {
      if (e.target.value.length > this.lengthOTP) {
        e.target.value = e.target.value.slice(0, this.lengthOTP);
      }
      this.$emit('changeData', e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-otp {
  width: 270px;
  font-size: 24px;

  .hide-input {
    color: transparent;
    letter-spacing: 67px;
    height: 32px;
  }

  .list-otp {
    width: 270px;

    .single-otp {
      width: 32px;
      height: 32px;
    }

    .single-otp:last-child {
      margin-right: 0;
    }
  }
}
</style>
