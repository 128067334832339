import Axios from '../services/axios';

const PATH = 'contracts';

type Pagination = {
  page: number;
  startDate?: string | null;
  endDate?: string | null;
  status?: string | null;
  limit?: number;
};

export function list({ page, startDate, endDate, status, limit }: Pagination) {
  return Axios.get(`${PATH}`, {
    params: {
      page,
      startDate,
      endDate,
      status,
      limit,
    },
  });
}

export function listGen({
  page,
  startDate,
  endDate,
  status,
  limit,
}: Pagination) {
  return Axios.get(`${PATH}/lead-gen`, {
    params: {
      page,
      startDate,
      endDate,
      status,
      limit,
    },
  });
}

export function createLoan(data: object) {
  return Axios.post(`${PATH}`, data);
}
