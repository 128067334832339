<template>
  <default-layout :isloading="loading">
    <div class="otp-info p-2 mt-7 text-center">
      <h2>Xác thực OTP</h2>
      <p class="mt-6">
        <b class="text-primary font-bold">Interloan</b> vừa gửi một mã xác minh
        có {{ LENGTH_OTP }} chữ số đến số điện thoại {{ hiddenPhone }}
      </p>
      <BaseOTP
        v-model:modelValue="model"
        :data="data"
        :length-o-t-p="LENGTH_OTP"
        @changeData="changeData"
        ref="otpInput"
        class="mt-8"
      />
      <p v-if="error" class="mt-1">{{ error }}</p>
      <p class="mt-8">Bạn không nhận được mã xác nhận?</p>
      <p
        class="cursor-pointer"
        :class="{
          'remain-time': true,
          'waiting-re-send': showTextWait,
        }"
        @click="reSend"
      >
        <span class="ready-sent uppercase underline font-bold">gửi lại</span>
        <span v-if="(countdown > 0) & showTextWait">
          sau {{ countdown }} giây
        </span>
      </p>
    </div>
  </default-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapMutations, useStore } from 'vuex';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseOTP from '../../components/Base/BaseOTP.vue';
import { hiddenNumber } from '@/utils';
import constRouter from '@/constants/constRouter';
import { confirmOTP, signUp } from '@/apis/ApiAuth';
import { createSalary } from '@/apis/ApiCreateNeeded';
import { createLoan } from '@/apis/ApiBorrower';
import { setAuthToken } from '@/helpers/localStorage';
export default defineComponent({
  name: 'ConfirmOTP',
  components: {
    BaseOTP,
    DefaultLayout,
  },
  setup() {
    const store = useStore();
    const phone = store.getters.getPhone;
    const password = store.getters.getPassword;
    const hiddenPhone = hiddenNumber(phone);
    const userName = store.getters.getName;
    const exigency = store.getters.getExigency;
    const LENGTH_OTP = 4;
    const TIME_COUNT_DOWN = 30;
    const data = [];
    for (let i = 0; i < LENGTH_OTP; i++) {
      data.push('');
    }
    return {
      LENGTH_OTP,
      TIME_COUNT_DOWN,
      data,
      phone,
      hiddenPhone,
      userName,
      exigency,
      password,
    };
  },
  data() {
    return {
      model: '',
      error: '',
      countdown: 0,
      loading: false,
      theInterval: null,
      isWaiting: true,
      showTextWait: false,
    };
  },
  computed: {
    ...mapGetters({
      campaign: 'getCampaign',
    }),
  },
  methods: {
    ...mapMutations({
      setContractId: 'setContractId',
    }),
    reSend() {
      signUp({
        fullName: this.userName,
        phone: this.phone,
        password: this.password,
      });
    },
    changeData(value: string) {
      this.model = value;
      this.data = value.split('');
      while (this.data.length < this.LENGTH_OTP) {
        this.data.push('');
      }
      if (value.length === this.LENGTH_OTP) {
        // turn off keyboard
        const ref: any = this.$refs;
        ref.otpInput.$refs.input.blur();
        this.loading = true;
        const { utmSource, utmCampaign, utmContent, clickId } = this.campaign;
        confirmOTP({
          pinCode: value,
          utmSource,
          utmCampaign,
          utmContent,
          clickId,
        })
          .then(res => {
            setAuthToken(res.data.token);
            this.loading = false;
            //ung luong
            if (this.exigency.isSalary) {
              createSalary({
                amount: this.exigency.amount,
              }).then(res => {
                this.setContractId(res.data?.data?.id);
              });
            }
            // //ung tien
            else {
              createLoan({
                amount: this.exigency.amount,
                term: this.exigency.term,
              }).then(res => {
                console.log(res.data.data.id);
                this.setContractId(res.data?.data?.id);
              });
            }
            this.$router.push({
              name: constRouter.REGISTER_COMPANY.name,
            });
          })
          .catch(err => {
            console.log(err);
            this.loading = false;
            this.model = '';
            for (let i = 0; i < this.LENGTH_OTP; i++) {
              this.data[i] = '';
            }
          });
      }
    },
  },
});
</script>
