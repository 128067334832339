import Axios from '../services/axios';
const PATH = 'borrow';

export function createSalary(data: object) {
  return Axios.post(`${PATH}/salary-advance`, data);
}

export function createInvest(data: object) {
  return Axios.post(`${PATH}/create-invest`, data);
}
